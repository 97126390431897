import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function MobileMenu(props) {

    const handleLi = () => {
        props.click();
    };



    useEffect(() => {
        if (props.mobileNavOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }

    }, [props.mobileNavOpen]);

    const currentPath = useLocation().pathname;

    return (
        <>
            <div className={`mobile-nav ${props.mobileNavOpen ? "open" : ""}`}>
                <div className="mobile-header">
                    <svg onClick={props.click} xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path d="M22.6273 21.2132L1.41406 0L-0.000151375 1.41421L21.2131 22.6274L22.6273 21.2132Z" fill="black" />
                        <path d="M21.2132 0.313897L0 21.5271L1.41421 22.9413L22.6274 1.72811L21.2132 0.313897Z" fill="black" />
                    </svg>
                </div>
                <div className="mobile-body">
                    <div className="menu">
                        <ul>
                            <li>
                                <Link arial-label="Aller à l'accueil" to="/" onClick={handleLi} className={currentPath === "/" ? "active" : ""}>Accueil</Link>
                            </li>
                            <li>
                                <Link arial-label="Aller à vêtements" to="/vetements" onClick={handleLi} className={currentPath === "/vetements" ? "active" : ""}>Vêtements</Link>
                            </li>
                            <li>
                                <Link arial-label="Aller à sweats" to="/sweats" onClick={handleLi} className={currentPath === "/sweats" ? "active" : ""}>Sweats</Link>
                            </li>
                            <li>
                                <Link arial-label="Aller à t-shirts" to="/t-shirts" onClick={handleLi} className={currentPath === "/t-shirts" ? "active" : ""}>T-shirts</Link>
                            </li>
                            <li>
                                <Link arial-label="Aller à accessoires" to="/accessoires" onClick={handleLi} className={currentPath === "/accessoires" ? "active" : ""}>Accessoires</Link>
                            </li>
                            <li>
                                <Link arial-label="Aller à bien-être" to="/bien-etre" onClick={handleLi} className={currentPath === "/bien-etre" ? "active" : ""}>Bien-être</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileMenu;