import React, { useRef, useEffect, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

function SwiperComponent(props) {
    const swiperContainer = useRef(null);
    const thumbsContainer = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (swiperContainer.current && thumbsContainer.current) {
            const mainSwiper = new Swiper(swiperContainer.current, {
                spaceBetween: 0,
                centeredSlides: false,
                loop: true,
                direction: 'horizontal',
                loopedSlides: 5,
                slidesPerView: 1, // Affichez une seule diapositive à la fois
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                resizeObserver: true,
                on: {
                    slideChange: () => {
                        setActiveIndex(mainSwiper.activeIndex);
                    }
                }
            });

            new Swiper(thumbsContainer.current, {
                spaceBetween: 0,
                centeredSlides: true,
                loop: true,
                slideToClickedSlide: true,
                direction: 'horizontal',
                slidesPerView: 5,
                loopedSlides: 5,
            });
        }
    }, []);

    return (
        <div className='component-swiperComponent'>
            <div className="main-slider" ref={swiperContainer}>
                <div
                    className="swiper-wrapper"
                    style={{ transform: `translateX(-${activeIndex * 100}%)` }}
                >
                    {props.product.images.map((image, index) => (
                        <div
                            className={`swiper-slide ${index === activeIndex ? 'active' : ''}`}
                            key={index}
                        >
                            <img src={image.src} alt={props.product.name} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="thumbs-slider" ref={thumbsContainer}>
                <div className="swiper-wrapper">
                    {props.product.images.map((image, index) => (
                        <div
                            className={`thumb ${index === activeIndex ? 'active' : ''}`}
                            key={index}
                            onClick={() => setActiveIndex(index)}
                        >
                            <img src={image.src} alt={props.product.name} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SwiperComponent;
