import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Cart from "./cart";
import { useCartOpen } from "../provider/cartOpen";
import { mutate } from "swr";
import { useNumberProduct } from "../provider/numberProductContext";
import size from "../tools/size";
import MobileMenu from "./mobileNav";

function Header() {

    const [lastScrollState, setLastScroll] = useState(0);
    const { numberProduct } = useNumberProduct();

    const { displayCart, setDisplayCart } = useCartOpen();
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const isMobile = size.isMobile();

    useEffect(() => {

        const componentHeader = isMobile ? document.querySelector(".component-header-mobile") : document.querySelector(".component-header");

        if (displayCart) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "unset";
        }

        const navOnScrollAccueil = () => {
            let lastScroll = lastScrollState;
            const currentScroll = window.pageYOffset;
            const body = document.body;
            const scrollUp = "scroll-up";
            const scrollDown = "scroll-down";
            var windowTop = window.scrollY;

            if (currentScroll <= 0) {
                body.classList.remove(scrollUp);
                return;
            }
            if (currentScroll <= 50) {
                return;
            }
            if (windowTop > 150) {
                componentHeader.classList.add("white");
            } else {
                if (!displayCart)
                    componentHeader.classList.remove("white");
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (
                currentScroll < lastScroll &&
                body.classList.contains(scrollDown)
            ) {
                // up 
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }

            setLastScroll(currentScroll);
        }

        const handleMouseOver = () => {
            componentHeader.classList.add("white");
        };

        const handleMouseOut = () => {
            if (window.scrollY < 150 && !displayCart) {
                componentHeader.classList.remove("white");
            }
        };

        if (window.location.pathname === "/") {
            window.addEventListener("scroll", navOnScrollAccueil);
            componentHeader.addEventListener("mouseover", handleMouseOver);
            componentHeader.addEventListener("mouseout", handleMouseOut);
        }

        return () => {
            window.removeEventListener("scroll", navOnScrollAccueil);
            componentHeader.removeEventListener("mouseover", handleMouseOver);
            componentHeader.removeEventListener("mouseout", handleMouseOut);
        };

    });

    const handleDisplayCart = () => {
        setDisplayCart(!displayCart);
        if (!displayCart) {
            mutate(process.env.REACT_APP_CACHE_CART);
        }
    };

    const handleMobileNav = () => {
        setMobileNavOpen(!mobileNavOpen);
    };

    const currentPath = useLocation().pathname;

    if (!isMobile) {
        return (
            <>
                {displayCart && (
                    <Cart />
                )}
                <div className={`component-header ${currentPath === "/" ? "" : "white"} ${displayCart ? "white" : ""}`}>
                    <div className="row">
                        <div className="logo">
                            <img src="/assets/coast_haven_logo.webp" alt="logo" />
                        </div>
                        <div className="menu">
                            <ul>
                                <li>
                                    <Link aria-label="Aller à l'accueil" to="/" className={currentPath === "/" ? "active" : ""}>Accueil</Link>
                                </li>
                                <li>
                                    <Link arial-label="Aller à vêtements" to="/vetements" className={currentPath === "/vetements" ? "active" : ""}>Vêtements</Link>
                                </li>
                                <li>
                                    <Link arial-label="Aller à sweats" to="/sweats" className={currentPath === "/sweats" ? "active" : ""}>Sweats</Link>
                                </li>
                                <li>
                                    <Link arial-label="Aller à t-shirts" to="/t-shirts" className={currentPath === "/t-shirts" ? "active" : ""}>T-shirts</Link>
                                </li>
                                <li>
                                    <Link to="/accessoires" arial-label="Aller à accessoires" className={currentPath === "/accessoires" ? "active" : ""}>Accessoires</Link>
                                </li>
                                <li>
                                    <Link to="/bien-etre" arial-label="Aller à bien-être" className={currentPath === "/bien-etre" ? "active" : ""}>Bien-être</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="icone">
                            <Link to="/mon-compte" arial-label="Aller à mon compte">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="icon icon-account" fill="none" viewBox="0 0 14 18">
                                    <path d="M7.34497 10.0933C4.03126 10.0933 1.34497 12.611 1.34497 15.7169C1.34497 16.4934 1.97442 17.1228 2.75088 17.1228H11.9391C12.7155 17.1228 13.345 16.4934 13.345 15.7169C13.345 12.611 10.6587 10.0933 7.34497 10.0933Z" stroke="currentColor"></path>
                                    <ellipse cx="7.34503" cy="5.02631" rx="3.63629" ry="3.51313" stroke="currentColor" stroke-linecap="square"></ellipse>
                                </svg>
                            </Link>

                            <div className="panier" onClick={handleDisplayCart}>
                                <svg class="icon icon-cart-empty" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
                                    <path d="m15.75 11.8h-3.16l-.77 11.6a5 5 0 0 0 4.99 5.34h7.38a5 5 0 0 0 4.99-5.33l-.78-11.61zm0 1h-2.22l-.71 10.67a4 4 0 0 0 3.99 4.27h7.38a4 4 0 0 0 4-4.27l-.72-10.67h-2.22v.63a4.75 4.75 0 1 1 -9.5 0zm8.5 0h-7.5v.63a3.75 3.75 0 1 0 7.5 0z" fill="currentColor" fill-rule="evenodd"></path>
                                </svg>
                                {numberProduct > 0 && <span className="panier-value">{numberProduct}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                {displayCart && (
                    <Cart />
                )}
                {mobileNavOpen && (
                    <MobileMenu click={handleMobileNav} mobileNavOpen={mobileNavOpen}/>
                )}
                <div className={`component-header-mobile ${mobileNavOpen ? "white" : ""} `}>
                    <div className="menu">
                        <svg onClick={handleMobileNav} xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                            <path d="M30 9H0V11H30V9Z" fill="white" />
                            <path d="M30 0H0V2H30V0Z" fill="white" />
                            <path d="M30 18H0V20H30V18Z" fill="white" />
                        </svg>
                    </div>
                    <div className="icone">
                        <Link to="/mon-compte">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="icon icon-account" fill="none" viewBox="0 0 14 18">
                                <path d="M7.34497 10.0933C4.03126 10.0933 1.34497 12.611 1.34497 15.7169C1.34497 16.4934 1.97442 17.1228 2.75088 17.1228H11.9391C12.7155 17.1228 13.345 16.4934 13.345 15.7169C13.345 12.611 10.6587 10.0933 7.34497 10.0933Z" stroke="currentColor"></path>
                                <ellipse cx="7.34503" cy="5.02631" rx="3.63629" ry="3.51313" stroke="currentColor" stroke-linecap="square"></ellipse>
                            </svg>
                        </Link>

                        <div className="panier" onClick={handleDisplayCart}>
                            <svg class="icon icon-cart-empty" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
                                <path d="m15.75 11.8h-3.16l-.77 11.6a5 5 0 0 0 4.99 5.34h7.38a5 5 0 0 0 4.99-5.33l-.78-11.61zm0 1h-2.22l-.71 10.67a4 4 0 0 0 3.99 4.27h7.38a4 4 0 0 0 4-4.27l-.72-10.67h-2.22v.63a4.75 4.75 0 1 1 -9.5 0zm8.5 0h-7.5v.63a3.75 3.75 0 1 0 7.5 0z" fill="currentColor" fill-rule="evenodd"></path>
                            </svg>
                            {numberProduct > 0 && <span className="panier-value">{numberProduct}</span>}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Header;