import React, { useState } from "react";
import useSWR from "swr";
import getProduct from "../api/product/getProduct";
import Chargement from "../components/chargement";
import Product from "../components/produit";
import top from "../tools/top";

function Vetements() {
    const [page, setPage] = useState(1);
    const [limit] = useState(12);
    const [sortType, setSortType] = useState("defaut");

    const { data: products, error } = useSWR(
        process.env.REACT_APP_CACHE_PRODUIT_VETEMENT,
        getProduct.bind(null)
    );


    const handlePageChange = (newPage) => {
        if (newPage < 1 || newPage > totalPages) {
            return;
        }
        setPage(newPage);
        top();
    };


    if (error) {
        return <p>Une erreur s'est produite lors du chargement des données.</p>;
    }

    if (!products) {
        return <Chargement />;
    }

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 2;

        for (let number = Math.max(1, page - maxPagesToShow); number <= Math.min(totalPages, page + maxPagesToShow); number++) {
            pageNumbers.push(
                <p
                    key={number}
                    className={number === page ? "active" : ""}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </p>
            );
        }

        return pageNumbers;
    };

    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const displayedProducts = products.slice(startIndex, endIndex);
    const totalPages = Math.ceil(products.length / limit);

    if (products.length === 0) {
        return <p>Aucun produit trouvé.</p>;
    }

    const handleSortChange = (event) => {
        setSortType(event.target.value);
    };
    const sortedProducts = [...displayedProducts];

    if (sortType === "prix-croissant") {
        sortedProducts.sort((a, b) => a.price - b.price);
    } else if (sortType === "prix-decroissant") {
        sortedProducts.sort((a, b) => b.price - a.price);
    } else if (sortType === "nom-croissant") {
        sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
    }

    return (
        <>
            <div className="page-vetements">
                <h2 className="titre">Vêtements</h2>
                <div className="page-vetements-filtre">
                    <div className="trie">
                        <select value={sortType} onChange={handleSortChange}>
                            <option value="defaut">Trier par défaut</option>
                            <option value="prix-croissant">Prix croissant</option>
                            <option value="prix-decroissant">Prix décroissant</option>
                            <option value="nom-croissant">Nom</option>
                        </select>
                    </div>
                </div>
                <div className="page-vetements-produit">
                    {sortedProducts.map((product) => (
                        <Product key={product.id} product={product} />
                    ))}
                </div>
                <div className="page-vetements-pagination">
                    <p
                        onClick={() => handlePageChange(page - 1)}
                    >
                        &lt; Précédent
                    </p>
                    {renderPageNumbers()}
                    <p
                        disabled={page === totalPages}
                        onClick={() => handlePageChange(page + 1)}
                    >
                        Suivant &gt;
                    </p>
                </div>
            </div>
        </>
    );
}

export default Vetements;
