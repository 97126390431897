import axios from "axios";

const getProductsByCategory = (id, limit) => {
  return axios
    .get(process.env.REACT_APP_URL_API + "wc/v2/products?category=" + id + "&per_page=" + limit)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}


export default getProductsByCategory;
