import React from "react";

class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: false,
            alertType: '',
            alertMessage: ''
        }
    }

    componentDidMount() {
        this.setState({
            alert: this.props.alert,
            alertType: this.props.alertType,
            alertMessage: this.props.alertMessage
        })
    }

    render() {
        if (this.state.alert) {
            return (
                <>
                    <div className='component-alert'>
                        <div className={this.state.alertType}>
                            <p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default Alert;

