import React from "react";
import getInformationUser from "../../api/compte/getInformationUser";
import useSWR from "swr";
import Chargement from "../../components/chargement";

function Information() {

  const { data: user, error } = useSWR(
    process.env.REACT_APP_CACHE_INFORMATION_USER,
    getInformationUser.bind(null)
  );

  if (error) {
    return <p>Une erreur s'est produite lors du chargement des données.</p>;
  }

  if (!user) {
    return <Chargement />;
  }

  return (
    <>
      <div className="layout-account-information">
        <input type="text" placeholder="Nom :" value={user.name ? "Nom : "+user.name : "Inconnu"} readOnly/>
        <input type="text" placeholder="Prénom :" value={user.surname ? "Prénom : "+user.surname : "Inconnu"} readOnly/>
        <input type="text" placeholder="Email :" value={user.email ? "Email : "+user.email : "Inconnu"} readOnly/>
      </div>
    </>
  );
}


export default Information;