class Format {

    formatPrice = (price) => {
        return new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
        }).format(price);
    };

    formatPriceCart = (price) => {
        return new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2, // Force deux décimales
            maximumFractionDigits: 2, // Force deux décimales
        }).format(price / 100); // Divisez par 100 pour ajuster le montant (ex. 2400 -> 24.00)
    }
    

}
 
// eslint-disable-next-line import/no-anonymous-default-export
export default new Format();