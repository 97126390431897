import axios from "axios";

class InscriptionApi {
  createUser(nom,prenom,email,motDePasse) {
    const newUser = {
        lastName : nom,
        firstName : prenom,
        email: email,
        password: motDePasse,
        roles: ["client"],
        username: email,
    };
    return axios.post( process.env.REACT_APP_URL_API + "my-api/v1/register", newUser);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InscriptionApi();