import axios from "axios";

const addCart = (parameter) => {
  return axios
    .post(process.env.REACT_APP_URL_API + "wc/store/cart/add-item", parameter, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export default addCart;
