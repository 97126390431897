import React, {useEffect} from "react";
import { useAuthContext } from "../provider/authentificateContext";
import Connexion from "./Connexion";
import Account from "../pages/Account";
import top from "../tools/top";

function Compte() {
  const { isConnected } = useAuthContext();

  useEffect(() => {
    top();
  }, []);

  if (!isConnected) {
    return <Connexion />;
  }

  return (
    <>
      <Account />
    </>
  );
}

export default Compte;
