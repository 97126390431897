import axios from "axios";

const getProduct = () => {
  return axios
    .get(process.env.REACT_APP_URL_API + "wc/v2/products?per_page=100")
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}


export default getProduct;
