import format from "../tools/format";
import removeItem from "../api/cart/removeItem";
import { mutate } from "swr";
import { useState } from "react";

function ProductCart(props) {

    const deleteItem = async () => {
        var data = {
            alert: true,
        };
        props.function(data);
        await removeItem(props.product.key);
        await mutate(process.env.REACT_APP_CACHE_CART);
        await mutate(process.env.REACT_APP_CACHE_CART_HEADER);
        data = {
            alert: false,
        };
        props.function(data);
    }

    const [quantity, setQuantity] = useState(props.product.quantity);

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            handleQuantityChange({ target: { value: quantity - 1 } });
        }
    };

    const handleIncrease = () => {
        setQuantity(quantity + 1);
        handleQuantityChange({ target: { value: quantity + 1 } });
    };

    const handleQuantityChange = (event) => {
        const newQuantity = parseInt(event.target.value);
        const updatedData = {
            key: props.product.key,
            quantity: newQuantity,
        };
        props.onQuantityChange(updatedData);
        setQuantity(newQuantity);
    };
        

    return (
        <>
            <div className="component-produit-cart">
                <div className="produit-info">
                    <img src={props.product.images[0].src} alt={props.product.name} />
                    <div className="produit-info-text">
                        <h3>{props.product.name}</h3>
                        <p>{props.product.variation.lenght === 2 ? props.product.variation[0].value + " - " + props.product.variation[1].value : props.product.variation[0].value}</p>
                    </div>
                </div>
                <div className="produit-items">
                    <div className="produit-items-price">
                        <div className="produit-items-price-quantity">
                            <button aria-label="Diminuer la quantité" className="minus" onClick={handleDecrease}>-</button>
                            <input type="number" value={quantity} readOnly/>
                            <button aria-label="Augmenter la quantité" className="plus" onClick={handleIncrease}>+</button>
                        </div>
                        <p>{format.formatPriceCart(props.product.totals.line_subtotal)}</p>
                    </div>
                    <div className="produit-items-delete">
                        <svg onClick={deleteItem} xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                            <path d="M14 1H10.25L9.9375 0.4375C9.8125 0.1875 9.5625 0 9.28125 0H5.6875C5.40625 0 5.15625 0.1875 5.03125 0.4375L4.75 1H1C0.71875 1 0.5 1.25 0.5 1.5V2.5C0.5 2.78125 0.71875 3 1 3H14C14.25 3 14.5 2.78125 14.5 2.5V1.5C14.5 1.25 14.25 1 14 1ZM2.15625 14.5938C2.1875 15.4062 2.84375 16 3.65625 16H11.3125C12.125 16 12.7812 15.4062 12.8125 14.5938L13.5 4H1.5L2.15625 14.5938Z" fill="black" />
                        </svg>
                        <p onClick={deleteItem}>Supprimer</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductCart;