import React from "react";
import useSWR from "swr";
import Product from "../../components/produit";
import { Link } from "react-router-dom";
import getProductsByCategory from "../../api/product/productCategory";
import size from "../../tools/size";
import Chargement from "../../components/chargement";

const ProduitCollection = () => {
  const { data: product, error } = useSWR(
    process.env.REACT_APP_CACHE_PRODUIT_ACCUEIL,
    getProductsByCategory.bind(null, process.env.REACT_APP_CATEGORY_ACCUEIL_PRODUCT,8)
  );

  if (error) {
    return <p>Une erreur s'est produite lors du chargement des données.</p>;
  }

  if (!product) {
    return <Chargement />;
  }

  if (product.length === 0) {
    return <p>Aucun produit trouvé.</p>;
  }

  const isMobile = size.isMobile();

  return (
    <div className="layout-accueil-produit-collection">
      <div className="titre-lien">
        <h2>Collection Wave</h2>
        {!isMobile && (
          <div className="voir-collection">
            <Link arial-label="Aller à la collection mise en avant" to="/">Voir la collection</Link>
          </div>
        )}
      </div>
      <div className="collection-produit">
        {product.map((product) => (
          <Product key={product.id} product={product} />
        ))}
      </div>
      {isMobile && (
        <div className="voir-collection">
          <Link arial-label="Aller à la collection mise en avant" to="/">Voir la collection</Link>
        </div>
      )}
    </div>
  );
};

export default ProduitCollection;
