import React from 'react';

class size  extends React.Component {

    isMobile () {
        return window.innerWidth <= process.env.REACT_APP_MOBILE;
    }

}
 
// eslint-disable-next-line import/no-anonymous-default-export
export default new size();