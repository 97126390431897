import React, { createContext, useContext, useState, useEffect } from "react";
import IsConnectedApi from "../api/compte/isConnectedApi";
import getSessionStorage from "../tools/getToken";

const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [isConnected, setIsConnected] = useState(false);
  const [token] = useState(getSessionStorage("token"));

  useEffect(() => {
    async function checkIsConnected() {
      try {
        const connected = await IsConnectedApi.isConnectedUser(token);
        setIsConnected(connected);
      } catch (error) {
        setIsConnected(false);
      }
    }

    checkIsConnected();

  }, [token]);

  return (
    <AuthContext.Provider value={{ isConnected, setIsConnected }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
