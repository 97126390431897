import React from "react";

class Stuff extends React.Component {
  render() {
    return (
      <>
        <div className="layout-accueil-stuff">
            <h2>Pour parfaire ton stuff</h2>
            <div className="row">
                <div className="col-md-4">
                    <img src="/assets/accueil-1.webp" alt="coast haven accueil"/>
                    <p>Smoking stuff <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" class="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                    </path></svg></p>
                </div>
                <div className="col-md-8">
                    <img src="/assets/accueil-2.webp" alt="coast haven accueil"/>
                    <p>Smoking stuff <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" class="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                    </path></svg></p>
                </div>
            </div>

        </div>
      </>
    );
  }
}

export default Stuff;