import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR, {mutate} from 'swr';
import getProductById from '../api/product/productById';
import SwiperComponent from '../components/swiperComponent';
import format from '../tools/format';
import Alert from '../components/alert';
import ProduitSimilaire from '../components/produitSimilaire';
import Chargement from '../components/chargement';
import addCart from '../api/cart/addCart';
import top from '../tools/top';
import { useCartOpen } from '../provider/cartOpen';

const DetailProduit = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    const { setDisplayCart } = useCartOpen();



    const { data: product, error } = useSWR(
        process.env.REACT_APP_CACHE_DETAIL_PRODUIT + id,
        getProductById.bind(null, id)
    );

    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertType, setAlertType] = useState("alert");

    const handleSizeSelection = (size) => {
        setSelectedSize(size);
    };

    const handleColorSelection = (color) => {
        setSelectedColor(color);
    };

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    useEffect(() => {
        top();
    });

    const handleIncrease = () => {
        setQuantity(quantity + 1);
    };

    const handleInputChange = (event) => {
        const newQuantity = parseInt(event.target.value);
        if (!isNaN(newQuantity) && newQuantity >= 1) {
            setQuantity(newQuantity);
        }
    };



    const addToCard = async (product) => {
        var parameter = null;
        if (product.attributes[0]?.name === "Taille" && product.attributes[1]?.name === "Couleur") {
            parameter = {
                id: product.id,
                quantity: quantity,
                variation: [
                    {
                        attribute: product.attributes[0].name,
                        value: selectedSize
                    },
                    {
                        attribute: product.attributes[1].name,
                        value: selectedColor
                    }
                ]
            }
        }
        else if (product.attributes[0]?.name === "Taille") {
            parameter = {
                id: product.id,
                quantity: quantity,
                variation: [
                    {
                        attribute: product.attributes[0].name,
                        value: selectedSize,
                    },
                ],
            }
        }
        else if (product.attributes[0]?.name === "Couleur") {
            parameter = {
                id: product.id,
                quantity: quantity,
                variation: [
                    {
                        attribute: product.attributes[0].name,
                        value: selectedColor
                    }
                ]
            }
        }
        setAlert(true);
        setAlertMessage("Le produit est en cours d'ajout.");
        setAlertType("warning");
        await addCart(parameter);
        setAlert(false);
        await mutate(process.env.REACT_APP_CACHE_CART);
        await mutate(process.env.REACT_APP_CACHE_CART_HEADER);
        setDisplayCart(true);
    };

    const handleAddToCart = () => {
        if (product.attributes[0]?.name === "Taille" && product.attributes[1]?.name === "Couleur") {
            if (selectedSize && selectedColor) {
                setAlert(false);
                addToCard(product);
            }
        }
        else if (product.attributes[0]?.name === "Taille") {
            if (selectedSize) {
                setAlert(false);
                addToCard(product);
            }
        }
        else if (product.attributes[0]?.name === "Couleur") {
            if (selectedColor) {
                setAlert(false);
                addToCard(product);
            }
        }
        if (product.attributes[0]?.name === "Taille" && product.attributes[1]?.name === "Couleur") {
            if (!selectedSize || !selectedColor) {
                setAlert(true);
                setAlertMessage("Veuillez sélectionner une taille et une couleur avant d'ajouter au panier.");
            }
        }
        else if (product.attributes[0]?.name === "Taille") {
            if (!selectedSize) {
                setAlert(true);
                setAlertMessage("Veuillez sélectionner une taille avant d'ajouter au panier.");
            }
        }
        else if (product.attributes[0]?.name === "Couleur") {
            if (!selectedColor) {
                setAlert(true);
                setAlertMessage("Veuillez sélectionner une couleur avant d'ajouter au panier.");
            }
        }
    };

    if (error) {
        return <p>Une erreur s'est produite lors du chargement des données.</p>;
    }

    if (!product) {
        return <Chargement />;
    }

    return (
        <>
            <div className='produit-page-alert'>
                {alert && (<Alert alert={true} alertType={alertType} alertMessage={alertMessage} />)}
                <div className="produit-page">
                    <div className="produit-swiper">
                        <SwiperComponent product={product} />
                    </div>
                    <div className="produit-information">
                        <h2>{product.name}</h2>
                        <p>{format.formatPrice(product.price)}</p>
                        <div className='layout-sizeAndColor'>
                            {product.attributes[0]?.name === "Taille" && (
                                <div className="size-buttons">
                                    <p>Taille disponible :</p>
                                    <div className="buttons-size">
                                        {product.attributes[0]?.options.map((size, index) => (
                                            <button
                                                aria-label={`Choisir une taille : ` + size}
                                                key={index}
                                                onClick={() => handleSizeSelection(size)}
                                                className={`size-button ${selectedSize === size ? 'active' : ''}`}
                                            >
                                                {size}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {product.attributes[1]?.name === "Couleur" && (
                                <div className='color-buttons'>
                                    <p>Couleur disponible :</p>
                                    <div className="color-items">
                                        {product.attributes[1]?.options.map((color, index) => (
                                            <div className="color-item" data-val={color} title={color} key={index}>
                                                <span className={`color-span ${selectedColor === color ? 'active' : ''}`} style={{ backgroundColor: color }}
                                                    onClick={() => handleColorSelection(color)}></span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="layout-product-description">
                            <p>Description : </p>
                            <div className="product-description" dangerouslySetInnerHTML={{ __html: product.description }} />
                        </div>
                        <div className="layout-product-quantityAndAddCard">
                            {product.stock_status === 'instock' ? (
                                <>
                                    <p>Quantité :</p>
                                    <div className='row'>
                                        <div className='contour'>
                                            <button className="quantity-button" aria-label="Diminuer la quantité" onClick={handleDecrease}>-</button>
                                            <input
                                                className="quantity-input"
                                                type="number"
                                                value={quantity}
                                                onChange={handleInputChange}
                                            />
                                            <button aria-label="Augmenter la quantité" className="quantity-button" onClick={handleIncrease}>+</button>
                                        </div>
                                        <button className="btn" aria-label="Ajouter au panier" onClick={handleAddToCart}>Ajouter au panier</button>
                                    </div>
                                </>
                            ) : (
                                <h3>Produit en rupture de stock</h3>
                            )}
                        </div>
                    </div>
                </div>
                <ProduitSimilaire product={product} />
            </div>
        </>
    );
}

export default DetailProduit;
