import axios from "axios";

class IsConnectedApi {
  async isConnectedUser(token) {
    if (token === undefined) {
      token = "";
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_URL_API + "jwt-auth/v1/token/validate",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.status === 200;
    } catch (error) {
      return false;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new IsConnectedApi();
