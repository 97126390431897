import React from "react";

class Commande extends React.Component {
  render() {
    return (
      <>
        <div className="layout-accueil-stuff">
            <p>commande</p>
        </div>
      </>
    );
  }
}

export default Commande;