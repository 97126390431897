import axios from "axios";

const getDetailProductById = (id) => {
    return axios
      .get(process.env.REACT_APP_URL_API + "wc/v3/products/" + id)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  };

  export default getDetailProductById;
