import React from "react";
import { Link } from "react-router-dom";
import format from "../tools/format";

class Product extends React.Component {

    render() {
        return (
            <>
                <Link arial-label={`Aller au produit ${this.props.product.name}`} to={`/produit?name=${this.props.product.slug}&id=${this.props.product.id}`}>
                    <div className="component-produit">
                        <div className="component-produit-image">
                            <img src={this.props.product.images[0].src} alt={this.props.product.images[0].alt} />

                            {this.props.product.images.length >= 2 && (
                                <img className="second-image" src={this.props.product.images[1].src} alt={this.props.product.images[1].alt} />
                            )}
                        </div>
                        <h2> {this.props.product.name}</h2>
                        <p className="price"> {format.formatPrice(this.props.product.price)}</p>
                        <p> {this.props.product.categories[0].name}</p>
                    </div>
                </Link>
            </>
        );
    }
}

export default Product;
