import axios from "axios";
import getSessionStorage from "../../tools/getToken";

const getInformationUser = () => {
  const token = getSessionStorage("token");
  console.log(token);
  return axios
    .get(process.env.REACT_APP_URL_API + "my-plugin/v1/user-info", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export default getInformationUser;