import React from "react";

class Instagram extends React.Component {
  render() {
    return (
      <>
        <div className="component-instagram">
            <div className="row">
                <div className="col-md-6 padding-right-5pc">
                    <h2>pour être <span>au courant de tout.</span></h2>
                    <p>
                        Abonnes toi à <strong>@Tealer</strong> et reste à l’affût de nos derniers 
                        drops, plonge dans notre univers et rejoins notre grande communauté 
                        <br/>En plus notre CM est un tueur.. 
                    </p>
                    <a className="btn" href="https://www.instagram.com/tealer/">Voir instagram</a>
                </div>
                <div class="col-md-6">
                    <img class="w-100" src='https://tealer.fr/cdn/shop/files/social.png?v=1665494733&width=900' alt='instagram coast haven'/>
                </div>
            </div>
        </div>
      </>
    );
  }
}

export default Instagram;

