import getProductsByCategory from "../api/product/productCategory";
import useSWR from "swr";
import Product from "../components/produit";
import Chargement from "../components/chargement";

function ProduitSimilaire(props) {

    const { data: product, error } = useSWR(
        process.env.REACT_APP_CACHE_PRODUCT_SIMILAIRE_ + props.product.categories[0].id,
        getProductsByCategory.bind(null, props.product.categories[0].id,4)
    );

    if (error) {
        return <p>Une erreur s'est produite lors du chargement des données.</p>;
    }

    if (!product) {
        return <Chargement />;
    }

    if (product.length === 0) {
        return <p>Aucun produit trouvé.</p>;
    }

    return (  
        <>
            <div className="component-produitSimilaire">
                <h2>Produit similaire</h2>
                <div className="produitSimilaire">
                    {product.map((product) => (
                        <Product key={product.id} product={product} />
                    ))}
                </div>
            </div>
        </>
    );
}

export default ProduitSimilaire;