import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../components/alert";
import ConnexionApi from "../api/compte/connexionApi";
import top from "../tools/top";

const Connexion = () => {
  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("Votre compte a bien été créé !");
  const [email, setEmail] = useState("");
  const [motDePasse, setMotDePasse] = useState("");

  const handleConnexion = () => {
    setAlert(false);

    ConnexionApi.loginUser(email, motDePasse)
      .then((response) => {
        if (response.data.token) {
          sessionStorage.setItem("token", response.data.token);
          window.location.reload(); //tmp
        }
      })
      .catch((error) => {
        setAlert(true);
        setAlertType("alert");
        setAlertMessage(error.response.data.message);
      });
  };

  useEffect(() => {
    top();
  });

  return (
    <div className="page-connexion">
      <div className="col-md-12">
        {alert && <Alert alert={true} alertType={alertType} alertMessage={alertMessage} />}
        <h1>Connexion</h1>
        <input
          type="text"
          placeholder="Email :"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Mot de passe :"
          value={motDePasse}
          onChange={(e) => setMotDePasse(e.target.value)}
        />
        <p className="left underline">Vous avez oublié votre mot de passe ?</p>
        <a className="btn" onClick={handleConnexion}>
          Se connecter
        </a>
        <Link to="/inscription">Créer un compte</Link>
      </div>
    </div>
  );
};

export default Connexion;
