import React, { useEffect, useState } from "react";
import Information from "../layouts/account/information";
import Commande from "../layouts/account/commande";
import top from "../tools/top";

function Account() {

  const [active, setActive] = useState(1);

  const handleClick = (index) => {
    setActive(index);
  };

  useEffect(() => {
    top();
  });

  return (
    <>
      <div className="page-account">
        <div className="row">
          <div className="col-md-2">
            <ul>
              <li
                className={active === 1 ? "haut active" : "haut"}
                onClick={() => handleClick(1)}
              >
                Information
              </li>
              <li
                className={active === 2 ? "top active" : "top"}
                onClick={() => handleClick(2)}
              >
                Commande
              </li>
              <li
                className={active === 3 ? "top bas active" : "top bas"}
                onClick={() => handleClick(3)}
              >
                Points
              </li>
            </ul>
          </div>
          <div className="col-md-10">
            {active === 1 ? <Information /> : ""}
            {active === 2 ? <Commande /> : ""}
          </div>
        </div>
      </div>
    </>
  );
}


export default Account;
