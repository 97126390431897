import axios from "axios";

const updateQuantity = (data) => {
    return axios
      .post(process.env.REACT_APP_URL_API + "wc/store/cart/update-item",data,
      {
        headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  };

  export default updateQuantity;
