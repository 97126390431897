import React, { createContext, useContext, useState } from 'react';

const CartOpen = createContext();

export const CartOpenProvider = ({ children }) => {
  const [displayCart, setDisplayCart] = useState(false);


  return (
    <CartOpen.Provider value={{ displayCart, setDisplayCart }}>
      {children}
    </CartOpen.Provider>
  );
};

export const useCartOpen = () => useContext(CartOpen);
