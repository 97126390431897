import React from "react";

class Chargement extends React.Component {
  render() {
    return (
      <>
        <div className="component-chargement">
          <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }
}

export default Chargement;

