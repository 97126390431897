import React, { createContext, useContext } from 'react';
import useSWR, { mutate } from 'swr';
import getNumberProductCart from '../api/cart/getNumberProductCart';

const NumberProductContext = createContext();

export const NumberCartProvider = ({ children }) => {

    const fetchAndUpdateData = async () => {
        try {
            const data = await getNumberProductCart();
            mutate(process.env.REACT_APP_CACHE_CART_HEADER, data.items_count, false);
        } catch (error) {
            console.error("Error fetching number product:", error);
        }
    };

    const { data: numberProduct } = useSWR(process.env.REACT_APP_CACHE_CART_HEADER, fetchAndUpdateData);

    return (
        <NumberProductContext.Provider value={{ numberProduct }}>
            {children}
        </NumberProductContext.Provider>
    );
};

export const useNumberProduct = () => useContext(NumberProductContext);
