import React from "react";
import Video from "../layouts/accueil/video";
import Instagram from "../components/instagram";
import Stuff from "../layouts/accueil/stuff";
import Engagement from "../components/engagement";
import ProduitCollection from "../layouts/accueil/produitCollection";

class Accueil extends React.Component {
  render() {
    return (
      <>
        <Video />
        <Stuff />
        <ProduitCollection />
        <Instagram />
        <Engagement />
      </>
    );
  }
}

export default Accueil;

