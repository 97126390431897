import axios from "axios";

const removeItem = (key) => {
    return axios
      .delete(process.env.REACT_APP_URL_API + "wc/store/cart/items/"+key,
      {
        headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  };

  export default removeItem;
