import { useCartOpen } from "../provider/cartOpen";
import getCart from "../api/cart/getCart";
import useSWR,{mutate} from "swr";
import Chargement from "./chargement";
import { useEffect, useState } from "react";
import ProductCart from "./produitCart";
import { Link } from "react-router-dom";
import format from "../tools/format";
import updateQuantity from "../api/cart/updateQuantity";

function Cart() {

    const { displayCart, setDisplayCart } = useCartOpen();
    const [alert, setAlert] = useState(false);
    const [updatedQuantities, setUpdatedQuantities] = useState([]);
    const [loadingQuantity, setLoadingQuantity] = useState(false);

    const { data: cart, error } = useSWR(
        process.env.REACT_APP_CACHE_CART,
        getCart.bind(null)
    );

    const handleQuantityChange = (updatedData) => {
        setUpdatedQuantities((prevQuantities) => [...prevQuantities, updatedData]);
    };
    
    const clickUpdate = async () => {
        setLoadingQuantity(true);
        for (const data of updatedQuantities) {
            await updateQuantity(data);
        }
        setUpdatedQuantities([]);
        await mutate(process.env.REACT_APP_CACHE_CART);
        await mutate(process.env.REACT_APP_CACHE_CART_HEADER);
        setLoadingQuantity(false);
    };

    const toggleCart = () => {
        setDisplayCart(!displayCart);

        if (!displayCart) {
            mutate(process.env.REACT_APP_CACHE_CART);
        }
    }

    const handleDataFromChild = (data) => {
        if (data) {
            setAlert(data.alert);
        }
    };

    useEffect(() => {
        if (displayCart) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [displayCart]);

    if (cart){
        var number = cart.length;
        var total = 0;
        for (let i = 0; i < cart.length; i++) {
            total += parseInt(cart[i].totals.line_subtotal);
        }
    }

    return (
        <>
            <div className={`cart-swiper ${displayCart ? "open" : ""}`}>
                <div className="cart-header">
                    <svg onClick={toggleCart} xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path d="M22.6273 21.2132L1.41406 0L-0.000151375 1.41421L21.2131 22.6274L22.6273 21.2132Z" fill="black"/>
                        <path d="M21.2132 0.313897L0 21.5271L1.41421 22.9413L22.6274 1.72811L21.2132 0.313897Z" fill="black"/>
                    </svg>
                    <div className="cart-link">
                        <Link to="/vetements" className="btn" onClick={toggleCart}>Continuer mes achats</Link>
                        <Link to="/checkout" className="btn">Commander</Link>
                    </div>
                </div>
                <div className="cart-body">
                    {error && <p>Une erreur s'est produite lors du chargement des données.</p>}
                    {(!cart || alert || loadingQuantity) && <Chargement />}
                    {cart && !alert && !loadingQuantity && cart.map((item, index) => (
                        <ProductCart key={index} product={item} function={handleDataFromChild} onQuantityChange={handleQuantityChange}/>
                    ))}
                </div>
                <div className="cart-footer">
                    <div className="cart-footer-update">
                        <button className="btn" onClick={clickUpdate} aria-label="Mettre à jour le panier">Mettre à jour le panier</button>
                    </div>
                    <div className="cart-footer-total">
                        <p>Nombres d'articles : {number}</p>
                        <p>Total : {format.formatPriceCart(total)}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cart;