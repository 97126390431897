import React from "react";
import Alert from "../components/alert";
import InscriptionApi from "../api/compte/inscriptionApi";
import top from "../tools/top";

class Inscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert : false,
      alertType : "success",
      alertMessage : "Votre compte a bien été créé !"
    };
  }

  componentDidMount() {
    top();
  }
  
  inscriptionApi = () => {
    this.setState({
      alert : false
    });
    const nom = document.querySelector("input[placeholder='Nom :']").value;
    const prenom = document.querySelector("input[placeholder='Prénom :']").value;
    const email = document.querySelector("input[placeholder='Email :']").value;
    const motDePasse = document.querySelector("input[placeholder='Mot de passe :']").value;

    InscriptionApi.createUser(nom, prenom, email, motDePasse)
      .then(response => {
        this.setState({
          alert : true,
          alertType : "success",
          alertMessage : "Votre compte a bien été créé !"
        });
      })
      .catch(error => {
        this.setState({
          alert : true,
          alertType : "alert",
          alertMessage : error.response.data.message
        });
      });
  }

  render() {
    return (
      <>
        <div className="page-inscription">
          <div className="col-md-12">
            {
              this.state.alert ? <Alert alert={true} alertType={this.state.alertType} alertMessage={this.state.alertMessage} /> : ""
            }
            <h1>Créer un compte</h1>
            <input type="text" placeholder="Nom :" />
            <input type="text" placeholder="Prénom :" />
            <input type="text" placeholder="Email :" />
            <input type="password" placeholder="Mot de passe :" />
            <a className="btn" onClick={this.inscriptionApi}>Créer</a>
          </div>
        </div>
      </>
    );
  }
}

export default Inscription;