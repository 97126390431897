import './styles/style.css';
import Footer from './components/footer';
import Accueil from "./pages/Accueil";
import Header from './components/header';
import Compte from './pages/Compte';
import Inscription from './pages/Inscription';
import Produit from './pages/Produit'
import Vetements from './pages/Vetements';
import Sweats from './pages/Sweats';
import Tshirt from './pages/T-shirt';
import Accessoire from './pages/Accessoire';
import BienEtre from './pages/BienEtre';

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header/>
          <Routes>
            <Route path="/" element={<Accueil/>} />
            <Route path="/mon-compte" element={<Compte/>} />
            <Route path="/inscription" element={<Inscription/>} />
            <Route path="*" element={<h1>404</h1>} />
            <Route path="/produit" element={<Produit/>} />
            <Route path="/vetements" element={<Vetements/>} />
            <Route path="/sweats" element={<Sweats/>} />
            <Route path="/t-shirts" element={<Tshirt/>} />
            <Route path="/accessoires" element={<Accessoire/>} />
            <Route path="/bien-etre" element={<BienEtre/>} />
          </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
