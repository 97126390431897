import React from "react";

class Engagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null
    };
  }

  handleAccordionClick = (index) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index
    }));
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <>
        <div className="component-engagement">
          <button aria-label="Ouvrir le menu déroulant" onClick={() => this.handleAccordionClick(0)}>Pour une qualité naturelle inégalée</button>
          <div style={{ maxHeight: activeIndex === 0 ? "100%" : 0 }}>
            <p>Nos vêtements Almanarre sont fabriqués en France avec des matériaux écologiques de la plus haute qualité, pour une durabilité inégalée et un impact minimal sur l'environnement</p>
          </div>
          <hr />
          <button aria-label="Ouvrir le menu déroulant" onClick={() => this.handleAccordionClick(1)}>Livraison rapide et responsable</button>
          <div style={{ maxHeight: activeIndex === 1 ? "100%" : 0 }}>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
          <hr />
          <button aria-label="Ouvrir le menu déroulant" onClick={() => this.handleAccordionClick(2)}>Retour facile et sans tracas</button>
          <div style={{ maxHeight: activeIndex === 2 ? "100%" : 0 }}>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
          <hr />
          <button aria-label="Ouvrir le menu déroulant" onClick={() => this.handleAccordionClick(3)}>Paiement sécurisé en ligne</button>
          <div style={{ maxHeight: activeIndex === 3 ? "100%" : 0 }}>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
          <hr />
        </div>
      </>
    );
  }
}

export default Engagement;