import axios from "axios";

const getNumberProductCart = () => {
    return axios
      .get(process.env.REACT_APP_URL_API + "wc/store/cart",{
        withCredentials: true,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  };

  export default getNumberProductCart;
