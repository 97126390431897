import React from "react";

class Video extends React.Component {
  render() {
    return (
      <>
        <div className="layout-accueil-video">
          <video autoPlay={true} loop={true} muted={true} playsInline={true}>
            <source src="/assets/video-accueil.mp4" type="video/mp4" />
          </video>
        </div>
      </>
    );
  }
}

export default Video;